'use client';

import React from 'react';
import dynamic from 'next/dynamic';

import { Container } from '@/components/Container';
import { AdditionalPaddings } from '@/components/Layouts/AdditionalPadding';
import { AwardsLayout } from '@/components/Layouts/AwardsLayout';
import { BlogLayout } from '@/components/Layouts/BlogLayout';
import Section from '@/components/Section/Section';
import { Awards } from '@/features/Awards/Awards';
import { Banner } from '@/features/Banner/Banner';
import { BookDemo } from '@/features/BookDemo/BookDemo';
import { DevelopmentBlock } from '@/features/DevelopmentBlock/DevelopmentBlock';
import { FAQ } from '@/features/FAQ/FAQ';
import { MainIntro } from '@/features/MainIntro/MainIntro';
// import { MainPricingBlock } from '@/features/MainPricingBlock/MainPricingBlock';
import { MainWhatIs } from '@/features/MainWhatIs/MainWhatIs';
import { OurReviews } from '@/features/OurReviews/OurReviews';
import { Partners } from '@/features/Partners/Partners';
import { Potential } from '@/features/Potential/Potential';
import { QuickbooksBlock } from '@/features/QuickbooksBlock/QuickbooksBlock';
import { SupportBlock } from '@/features/SupportBlock/SupportBlock';
// import { Services } from '@/features/Services/Services';
import { Testimonals } from '@/features/Testimonals/Testimonals';
import ClientOnly from '@/helpers/ClientOnly';
import {
  awards,
  awards2,
  blog,
  bookDemo,
  developFeatures,
  faq,
  partners,
  potential,
  reviews,
  services,
  testimonals,
  whatIs,
} from '@/lib/home/home';
// import { module1, module2, module3, module4 } from '@/lib/pricing/pricing';

const ServicesDynamic = dynamic(() =>
  import('@/features/Services/Services').then((com) => com.Services)
);

const BlogDynamic = dynamic(() => import('@/features/Blog/Blog'), {
  ssr: false,
});

const HomePage = () => {
  return (
    <>
      <MainIntro />
      <AdditionalPaddings>
        <Container>
          <Section>
            <MainWhatIs isVideo={true} {...whatIs} />
          </Section>
        </Container>
      </AdditionalPaddings>

      <AdditionalPaddings>
        <Container>
          <Section>
            <Potential {...potential} />
          </Section>
        </Container>
      </AdditionalPaddings>

      <QuickbooksBlock />

      <Container>
        <Section title={services.title} description={services.description}>
          <ServicesDynamic {...services} />
        </Section>
      </Container>

      <Section>
        <AdditionalPaddings>
          <Banner
            title={`Schedule Smarter, Manage Customer Relations,`}
            secondaryTitle="And Enhance Team Productivity — All with Free Access!"
            subTitle="Take the first step towards effective business management today"
            listItems={[]}
          />
        </AdditionalPaddings>
      </Section>

      <Section>
        <AdditionalPaddings>
          <Container>
            <OurReviews item={reviews} />
          </Container>
        </AdditionalPaddings>
      </Section>
      <SupportBlock />

      {/* <BlogLayout>
        <Container>
          <Section title="Pricing">
            <MainPricingBlock items={[module1, module2, module3, module4]} />
          </Section>
        </Container>
      </BlogLayout> */}

      <Section>
        <AdditionalPaddings>
          <Container>
            <DevelopmentBlock items={developFeatures} />
          </Container>
        </AdditionalPaddings>
      </Section>

      <AwardsLayout>
        <Container>
          <Section
            title="Our Awards"
            description="Orcatec takes pride in its numerous awards, a testament to our commitment to innovation and excellence in the field of business software"
          >
            <Awards awardsV2={awards2} {...awards} />
          </Section>
        </Container>

        <Section>
          <Banner
            title={` Take The Next Step And Try Our Solutions`}
            subTitle="Ready to boost your home services business?"
            listItems={[
              { title: 'Free version' },
              { title: 'Onboarding and support' },
              { title: 'Data migration' },
              { title: '24/7 customer service' },
            ]}
          />
        </Section>

        <Section>
          <Container>
            <Testimonals {...testimonals} />
          </Container>
        </Section>
      </AwardsLayout>

      <Container>
        <AdditionalPaddings>
          <Section title="Our partners">
            <Partners {...partners} />
          </Section>
        </AdditionalPaddings>
      </Container>

      <Container>
        <Section title={faq.title}>
          <FAQ {...faq} />
        </Section>
      </Container>

      <BlogLayout>
        <Container>
          <Section title={blog.title} description={blog.description}>
            <ClientOnly>
              <BlogDynamic />
            </ClientOnly>
          </Section>
        </Container>
      </BlogLayout>

      <BookDemo {...bookDemo} />
    </>
  );
};

export default HomePage;
